import { GetExistingActionSampleResponseSchema } from '@shared/schema/existing-action-sample.get.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function actionExistingSampleQuery(siteId: number) {
  return queryOptions({
    queryKey: ['site', siteId, 'equipment', 'existing-sample'],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/action/existing-sample`)

      if (!rawResponse.ok) {
        throw new Error('Failed to fetch action existing sample')
      }

      try {
        return await GetExistingActionSampleResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse action existing sample response', { cause: error })
      }
    },
  })
}
