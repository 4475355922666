import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { parseDateRange } from '@/lib/date'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'

export const defaultDateRange = parseDateRange({
  startDate: dayjs().subtract(30, 'day').toDate(),
  endDate: dayjs().toDate(),
})

export const SearchSchema = z.object({
  tab: z.union([z.literal('my-alerts'), z.literal('alert-configuration')]).default('my-alerts'),
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/site/$id/alerts/')({
  validateSearch: SearchSchema,
  beforeLoad({ search, context }) {
    if (search.tab === 'alert-configuration' && !context.user?.hasPermission(Permission.ALERT_CONFIGURATION)) {
      throw redirect({
        to: '/403',
      })
    }

    if (isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
