import { type ComponentProps } from 'react'

import { OuiSenseIcon } from '@/icons'
import { cn } from '@/lib/utils'

export function FullScreenLoader({ className, fill }: ComponentProps<'svg'>) {
  return (
    <div className="fixed inset-0 z-50 h-full w-full overflow-y-auto">
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className="flex animate-pulse space-x-4">
          <OuiSenseIcon fill={fill} className={cn('size-16', className)} />
        </div>
      </div>
    </div>
  )
}
