import { GetAllSiteSchemaResponse } from '@shared/schema/site.get-all.schema'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { apiFetch } from '@/lib/api-fetch'

export const mySitesQuery = queryOptions({
  queryKey: ['site', 'all'],
  async queryFn() {
    const rawResponse = await apiFetch.get('site')

    if (!rawResponse.ok) {
      throw new Error('Failed to fetch sites')
    }

    try {
      return await GetAllSiteSchemaResponse.parseAsync(await rawResponse.json())
    } catch (error) {
      throw new Error('Failed to parse sites response', { cause: error })
    }
  },
})

export type Site = QueryOptionsData<typeof mySitesQuery>[number]
