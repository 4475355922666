import { ActionCategory } from '@core/referential/action-category.enum'
import { ActionStatus } from '@core/referential/action-status.enum'
import { ActionType } from '@core/referential/action-type.enum'
import { ConditionType } from '@core/referential/condition-type.enum'
import { DeviceSubType } from '@core/referential/device-sub-type.enum'
import { Frequency } from '@core/referential/frequency.enum'
import { IncidentStatus } from '@core/referential/incident-status.enum'
import { ProcedureType } from '@core/referential/procedure-type.enum'
import { WaterType } from '@core/referential/water-type.enum'
import { conditionSchema, numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetNoConformityByIdActionReadingPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
  actionReadingId: z.string().uuid(),
})

export const GetNoConformityByIdActionReadingResponseSchema = z.object({
  actionParamId: z.string().uuid(),
  actionReadingId: z.string().uuid(),
  actionName: z.string(),
  siteId: numberOrStringToNumberSchema,
  equipmentId: z.string().uuid(),
  equipmentName: z.string(),
  status: z.nativeEnum(ActionStatus),
  category: z.nativeEnum(ActionCategory),
  doneBy: z.string().nullable(),
  doneDate: z.union([z.string().datetime({ offset: true }), z.date()]).nullable(),
  startDate: z.union([z.string().datetime({ offset: true }), z.date()]).nullable(),
  deadlineDate: z.union([z.string().datetime({ offset: true }), z.date()]).nullable(),
  lateDate: z.union([z.string().datetime({ offset: true }), z.date()]).nullable(),
  comment: z.string().nullable(),
  commentIncident: z.string().nullable(),
  incident: z.boolean().nullable(),
  companyResp: z.string().nullable(),
  firstResp: z.string().email().nullable(),
  firstResp2: z.string().email().nullable(),
  secondaryResp: z.array(z.string().email()).nullable(),
  descriptionSpot: z.string().nullable(),
  frequency: z.nativeEnum(Frequency),
  type: z.nativeEnum(ActionType),
  tempRange: z.array(z.union([z.number(), z.string()])).nullable(),
  value: z.number().nullable(),
  building: z.string().nullable(),
  buildingId: numberOrStringToNumberSchema.nullable(),
  floor: z.string().nullable(),
  floorId: numberOrStringToNumberSchema.nullable(),
  space: z.string().nullable(),
  spaceId: numberOrStringToNumberSchema.nullable(),
  conditionId: numberOrStringToNumberSchema.nullable(),
  conformIfCondition: z.boolean().nullable(),
  condition: conditionSchema.nullable(),
  conditionType: z.nativeEnum(ConditionType).nullable(),
  conformity: z.boolean().nullable(),
  manual: z.boolean().nullable(),
  unit: z.string().nullable(),
  procedureId: numberOrStringToNumberSchema,
  procedureName: z.string(),
  procedureType: z.nativeEnum(ProcedureType),
  description: z.string(),
  actionReadingIncidentFileId: z.string().uuid().nullable(),
  deviceId: z.string().uuid().nullable(),
  waterType: z.nativeEnum(WaterType).nullable().optional(),
  incidentStatus: z.nativeEnum(IncidentStatus).nullable().optional(),
  incidentResolution: z.string().nullable().optional(),
  deviceSubType: z.nativeEnum(DeviceSubType).nullable().optional(),
  actionReadingFiles: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        date: z.string(),
      }),
    )
    .nullable()
    .optional(),
})

export type GetNoConformityByIdActionReadingPathParameter = z.infer<typeof GetNoConformityByIdActionReadingPathParameterSchema>
export type GetNoConformityByIdActionReadingResponse = z.infer<typeof GetNoConformityByIdActionReadingResponseSchema>
