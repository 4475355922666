import { DTDeviceType } from '@core/referential/dt-device-type.enum'

export enum DTEventType {
  TOUCH = 'touch',
  TEMPERATURE = 'temperature',
  OBJECT_PRESENT = 'objectPresent',
  WATER_PRESENT = 'waterPresent',
  MOTION = 'motion',
  CONNECTION_STATUS = 'connectionStatus',
  ETHERNET_STATUS = 'ethernetStatus',
  BATTERY_STATUS = 'batteryStatus',
  BATTERY = 'battery',
  NETWORK_STATUS = 'networkStatus',
  CELLULAR_STATUS = 'cellularStatus',
  WATER_MOVEMENT = 'waterMovement',
  WATER_MOVEMENT_TEMPERATURE = 'waterMovementTemperature',
  CO2 = 'co2',
  HUMIDITY = 'humidity',
  WATER_FLOW = 'waterFlow',
  WATER = 'water',
  GAS = 'gas',
  ELECTRICITY = 'electricity',
  FUEL = 'fuel',
  ENERGY = 'energy',
  OTHER_ENERGY = 'otherEnergy',
}

export const CoreData = [
  DTEventType.TOUCH,
  DTEventType.TEMPERATURE,
  DTEventType.OBJECT_PRESENT,
  DTEventType.WATER_PRESENT,
  DTEventType.MOTION,
  DTDeviceType.HUMIDITY,
  DTEventType.WATER_MOVEMENT,
  DTEventType.WATER_MOVEMENT_TEMPERATURE,
  DTEventType.CO2,
  DTEventType.WATER_FLOW,
  DTEventType.WATER,
  DTEventType.GAS,
  DTEventType.ELECTRICITY,
  DTEventType.FUEL,
  DTEventType.ENERGY,
  DTEventType.OTHER_ENERGY,
] as Array<string>
export const NonCoreData = [
  DTEventType.CONNECTION_STATUS,
  DTEventType.ETHERNET_STATUS,
  DTEventType.BATTERY_STATUS,
  DTEventType.NETWORK_STATUS,
  DTEventType.CELLULAR_STATUS,
  DTEventType.BATTERY,
] as Array<string>
