import { GetEquipmentWithActionsOrSamplesResponseSchema } from '@shared/schema/equipment.with-actions-or-samples.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function equipmentsWithActionsQuery(siteId: number) {
  return queryOptions({
    queryKey: ['site', siteId, 'equipment', 'with-actions'],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/equipment/list-with-actions`)

      if (!rawResponse.ok) {
        throw new Error('Failed to fetch equipments with actions')
      }

      try {
        return await GetEquipmentWithActionsOrSamplesResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse equipments with actions response', { cause: error })
      }
    },
  })
}
