import { Role } from '@core/referential/role.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { z } from 'zod'

export const GetUserByIdPathParameterSchema = z.object({
  userId: z.string().uuid(),
})

export const GetUserByIdSchemaResponse = z.object({
  id: z.string().uuid(),
  firstname: z.string(),
  lastname: z.string(),
  phone: z.string().optional().nullable(),
  mail: z.string().email(),
  role: z.nativeEnum(Role),
  sites: z.array(z.number()).nullable().optional(),
  equipments: z.array(z.string().uuid()).nullable().optional(),
  useCases: z.array(z.nativeEnum(UseCase)),
})

export type GetUserByIdPathParameter = z.infer<typeof GetUserByIdPathParameterSchema>
export type GetUserByIdResponse = z.infer<typeof GetUserByIdSchemaResponse>
