import { type ActionCategory } from '@core/referential/action-category.enum'
import { GetListByCategoryActionParamResponseSchema } from '@shared/schema/action-param.list-by-category.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function actionParamsListByCategoryQuery(siteId: number, category: ActionCategory) {
  return queryOptions({
    queryKey: ['site', siteId, 'action-param', 'list-by-category', category],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/action/list-by-category?category=${category}`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch action params by category ${siteId}`)
      }

      try {
        return await GetListByCategoryActionParamResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse action params by category response', { cause: error })
      }
    },
  })
}
