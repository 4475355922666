import { GetReportConfigurationResponseSchema } from '@shared/schema/reporting.get-all-configuration.schema'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { type SiteId } from '@/@types/site'
import { apiFetch } from '@/lib/api-fetch'

export function reportConfigurationsQuery(siteId: SiteId) {
  return queryOptions({
    queryKey: ['site', siteId, 'report-configurations'],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/report/configuration`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch report configurations for site ${siteId}`)
      }

      try {
        return await GetReportConfigurationResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse report configurations response', { cause: error })
      }
    },
  })
}

export type ReportConfiguration = QueryOptionsData<typeof reportConfigurationsQuery>[number]
