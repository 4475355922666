import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { createDefaultErrorComponent } from '@/create-default-error-component'
import { equipmentRefsSamplesQuery } from '@/queries/equipment-refs-samples.query'
import { equipmentsWithSamplingsQuery } from '@/queries/equipments-with-samplings.query'

export const Route = createFileRoute('/_dashboard/site/$id/equipment/choose-sample/')({
  async loader({ params, context: { queryClient } }) {
    await queryClient.ensureQueryData(equipmentsWithSamplingsQuery(params.id, { action: 'show' }))
    await queryClient.ensureQueryData(equipmentRefsSamplesQuery)
  },
  errorComponent: createDefaultErrorComponent(t('cannotLoadSamples')),
})
