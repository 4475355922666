import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { ChevronDownIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { type QueryOptionsData } from '@/@types/query'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { type equipmentQuery } from '@/queries/equipment.query'
import { siteQuery } from '@/queries/site.query'

export function EditEquipmentBreadcrumb({
  equipment,
  siteId,
  pathname,
  action,
  showActions,
}: {
  siteId: number
  equipment: QueryOptionsData<typeof equipmentQuery>
  pathname: string
  action: 'preventive' | 'sample'
  showActions: boolean
}) {
  const { t } = useTranslation()
  const site = useSuspenseQuery(siteQuery(siteId))
  const params = { id: siteId, equipmentId: equipment.equipmentId }
  const isInformationLinkActive = pathname.endsWith('/information')
  const isDevicesLinkActive = pathname.endsWith('/devices')
  const isActionsLinkActive = pathname.endsWith('/actions')

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to="/site/$id/equipment" params={params} search={{ tab: action === 'sample' ? 'samplings' : action }}>
              {site.data.name}
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>{equipment.name}</BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <DropdownMenu>
            <DropdownMenuTrigger className="flex items-center gap-1">
              {isInformationLinkActive && t('dashboard.sidebar.information')}
              {isActionsLinkActive && t('actions')}
              {isDevicesLinkActive && t('devices')}
              <ChevronDownIcon className="size-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuItem asChild>
                <Link to="/site/$id/equipment/$equipmentId/information" params={params} search={{ action }}>
                  {t('dashboard.sidebar.information')}
                </Link>
              </DropdownMenuItem>
              {action !== 'sample' && equipment.canHaveDevice && (
                <DropdownMenuItem asChild>
                  <Link to="/site/$id/equipment/$equipmentId/devices" params={params} search={{ action }}>
                    {t('devices')}
                  </Link>
                </DropdownMenuItem>
              )}
              {showActions && (
                <DropdownMenuItem asChild>
                  <Link to="/site/$id/equipment/$equipmentId/actions" params={params} search={{ action }}>
                    {t('actions')}
                  </Link>
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}
