import { type NonCoreMetadata } from '@core/database/interfaces/database.interface'
import { type UseCase } from '@core/referential/use-case.enum'
import { isEmpty } from 'lodash-es'
import { twMerge } from 'tailwind-merge'

import { useAuth } from '@/hooks/use-auth'

export function cn(...inputs: Parameters<typeof twMerge>) {
  const classes = twMerge(inputs)

  if (classes === '') return undefined

  return classes
}

export function isUseCase(useCase: UseCase) {
  const { user } = useAuth.getState()

  return user?.currentUseCase === useCase
}

export function isOffline(nonCoreMetadata: NonCoreMetadata | undefined | null) {
  return (
    nonCoreMetadata?.cloudConnectorConnectionStatus === 'OFFLINE' ||
    isEmpty(nonCoreMetadata) ||
    nonCoreMetadata?.sensorSignalStrengh === 0 ||
    nonCoreMetadata?.cloudConnectorCellularSignalStrength === 0
  )
}
