import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { equipmentQuery } from '@/queries/equipment.query'

export const Route = createFileRoute('/_dashboard/site/$id/equipment/$equipmentId/_layout/information')({
  parseParams(params) {
    return z
      .object({
        equipmentId: z.string().uuid(),
      })
      .parse(params)
  },
  loaderDeps({ search: { action } }) {
    return { action }
  },
  async loader({ params, context: { queryClient }, deps: { action } }) {
    await queryClient.ensureQueryData(equipmentQuery(params.id, params.equipmentId, { action }))
  },
})
