import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { createFileRoute, notFound } from '@tanstack/react-router'
import { z } from 'zod'

import { equipmentRefsSamplesQuery } from '@/queries/equipment-refs-samples.query'
import { equipmentRefsQuery } from '@/queries/equipment-refs.query'

export const Route = createFileRoute('/_dashboard/site/$id/equipment/create/')({
  validateSearch(search) {
    return z
      .object({
        equipmentRefId: numberOrStringToNumberSchema,
        action: z.union([z.literal('preventive'), z.literal('sample')]).default('preventive'),
      })
      .parse(search)
  },
  loaderDeps({ search }) {
    return {
      equipmentRefId: search.equipmentRefId,
      action: search.action,
    }
  },
  async loader({ deps: { equipmentRefId, action }, context: { queryClient } }) {
    let refs = []
    if (action === 'preventive') {
      refs = await queryClient.ensureQueryData(equipmentRefsQuery)
    } else if (action === 'sample') {
      refs = await queryClient.ensureQueryData(equipmentRefsSamplesQuery)
    } else {
      throw notFound()
    }

    const refToUse = refs.find((ref) => ref.id === equipmentRefId)

    if (!refToUse) {
      throw notFound()
    }

    return refToUse
  },
})
