import { GetAlertByIdResponseSchema } from '@shared/schema/alert.get-by-id.schema'
import { queryOptions } from '@tanstack/react-query'

import { type SiteId } from '@/@types/site'
import { apiFetch } from '@/lib/api-fetch'

const fetchAlertDetail = async (siteId: SiteId, alertId: string) => {
  const rawResponse = await apiFetch.get(`site/${siteId}/alert/${alertId}/detail`)

  if (!rawResponse.ok) {
    throw new Error(`Failed to fetch alert detail for site ${siteId} and alert ${alertId}`)
  }

  try {
    return await GetAlertByIdResponseSchema.parseAsync(await rawResponse.json())
  } catch (error) {
    throw new Error('Failed to parse alert detail response', { cause: error })
  }
}

export function alertDetailsQuery(siteId: SiteId, alertId?: string) {
  return queryOptions({
    queryKey: ['site', siteId, 'alert', alertId, 'details'],
    queryFn: () => (alertId ? fetchAlertDetail(siteId, alertId) : null),
  })
}
