import { type ComponentProps } from 'react'
import { Toaster as Sonner } from 'sonner'

function Toaster({ ...props }: ComponentProps<typeof Sonner>) {
  return (
    <Sonner
      theme="light"
      className="toaster group"
      toastOptions={{
        classNames: {
          toast: 'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          warning: '[&_[data-icon]]:text-orange-400',
          success: '[&_[data-icon]]:text-green-400',
          error: '[&_[data-icon]]:text-destructive',
        },
        closeButton: true,
      }}
      {...props}
    />
  )
}

export { Toaster }
