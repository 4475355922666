import { GetUserByIdSchemaResponse } from '@shared/schema/user.get-by-id.schema'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { apiFetch } from '@/lib/api-fetch'

export function userDetailsQuery(userId?: string) {
  return queryOptions({
    queryKey: ['user', userId],
    async queryFn() {
      const rawResponse = await apiFetch.get(`user/${userId}`)

      if (!rawResponse.ok) {
        throw new Error('Failed to fetch user details')
      }

      try {
        return await GetUserByIdSchemaResponse.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse user details response', { cause: error })
      }
    },
    enabled: !!userId,
  })
}

export type User = QueryOptionsData<typeof userDetailsQuery>
