import { OuiSenseIcon } from '@/icons'
import { cn } from '@/lib/utils'

export default function Loader({ className, wrapperClassName, fill }: { className?: string; wrapperClassName?: string; fill?: string }) {
  return (
    <div className={cn('flex animate-pulse', wrapperClassName)}>
      <OuiSenseIcon className={cn('size-16', className)} fill={fill} />
    </div>
  )
}
