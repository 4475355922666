import { GetUserSchemaResponse } from '@shared/schema/user.get.schema'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { apiFetch } from '@/lib/api-fetch'

export const usersQuery = queryOptions({
  queryKey: ['users'],
  async queryFn() {
    const rawResponse = await apiFetch.get('user')

    if (!rawResponse.ok) {
      throw new Error('Failed to fetch users')
    }

    try {
      return await GetUserSchemaResponse.parseAsync(await rawResponse.json())
    } catch (error) {
      throw new Error('Failed to parse users response', { cause: error })
    }
  },
})

export type User = QueryOptionsData<typeof usersQuery>[number]
