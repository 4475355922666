import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'

export const Route = createFileRoute('/_dashboard/site/$id/reports/configuration/create/')({
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
