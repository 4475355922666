import { type SVGProps } from 'react'

export function OuiSenseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg version="1.1" id="svg2" width="101.33333" height="101.33333" viewBox="0 0 101.33333 101.33333" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs id="defs6">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath16">
          <path d="M 0,76 H 76 V 0 H 0 Z" id="path14" />
        </clipPath>
      </defs>
      <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,101.33333)">
        <g id="g10">
          <g id="g12" clipPath="url(#clipPath16)">
            <g id="g18" transform="translate(69.6285,17.3159)">
              <path
                d="m 0,0 c 0,-6.031 -4.906,-10.944 -10.944,-10.944 h -5.003 v 31.872 c 0,8.491 -6.946,15.437 -15.438,15.437 h -31.872 v 5.004 c 0,6.038 4.913,10.944 10.944,10.944 h 41.369 C -4.906,52.313 0,47.407 0,41.369 Z m -52.32,-10.944 c -6.04,0 -10.937,4.896 -10.937,10.936 0,6.04 4.897,10.937 10.937,10.937 6.04,0 10.936,-4.897 10.936,-10.937 0,-6.04 -4.896,-10.936 -10.936,-10.936 m 41.376,-4.501 c 8.491,0 15.445,6.953 15.445,15.445 v 41.369 c 0,8.491 -6.954,15.444 -15.445,15.444 h -41.369 c -8.491,0 -15.445,-6.953 -15.445,-15.444 V 0 c 0,-8.492 6.954,-15.445 15.445,-15.445 z"
                style={{
                  fill: props.fill ?? '#0c0ef6',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path20"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
