import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetActionByUserResponseSchema = z.array(
  z.object({
    siteId: numberOrStringToNumberSchema,
    siteName: z.string(),
    nbActionToDo: numberOrStringToNumberSchema,
    nbActionLate: numberOrStringToNumberSchema,
  }),
)

export type GetActionByUserResponse = z.infer<typeof GetActionByUserResponseSchema>
