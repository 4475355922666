import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetAlertCommentPathParameterSchema = z.object({
  alertId: numberOrStringToNumberSchema,
})

export const GetAlertCommentResponseSchema = z.array(
  z.object({
    id: numberOrStringToNumberSchema,
    alertId: numberOrStringToNumberSchema,
    text: z.string().nullable(),
    createdAt: z
      .union([z.string(), z.date()])
      .transform((date) => new Date(date))
      .nullable(),
    updatedAt: z
      .union([z.string(), z.date()])
      .transform((date) => new Date(date))
      .nullable(),
  }),
)

export type GetAlertCommentPathParameter = z.infer<typeof GetAlertCommentPathParameterSchema>
export type GetAlertCommentResponse = z.infer<typeof GetAlertCommentResponseSchema>
