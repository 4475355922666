import { GetActionParamResponseSchema } from '@shared/schema/action-param.get.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function actionParamsQuery(siteId: number, equipmentId: string, { mode, action }: { mode: 'edit' | 'create'; action: 'preventive' | 'sample' }) {
  return queryOptions({
    queryKey: ['site', siteId, 'equipment', equipmentId, 'action-params', { mode, action }],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/action/${equipmentId}?mode=${mode}&action=${action}`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch action params ${siteId}`)
      }

      try {
        return await GetActionParamResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse action params response', { cause: error })
      }
    },
  })
}
