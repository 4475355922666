import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { alertDetailsQuery } from '@/queries/alert-detail.query'

export const Route = createFileRoute('/_dashboard/site/$id/alerts/edit/$alertId/')({
  loader({ params, context: { queryClient } }) {
    return queryClient.ensureQueryData(alertDetailsQuery(params.id, params.alertId))
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
