import { fetchAuthSession } from 'aws-amplify/auth'
import ky from 'ky'

import { useAuth } from '@/hooks/use-auth'

export const fetch = ky.extend({
  hooks: {
    beforeRequest: [
      async (request) => {
        const currentSession = await fetchAuthSession()
        const { user } = useAuth.getState()

        if (!currentSession.tokens?.idToken) {
          window.location.replace('/login')

          return
        }

        const token = currentSession.tokens.idToken.toString()

        request.headers.set('Authorization', `Bearer ${token}`)
        request.headers.set('xxx-ouisense-use-case', user?.currentUseCase ?? '')
      },
    ],
  },
  timeout: 60_000,
})

export default fetch
