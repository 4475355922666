import { DeviceFamily } from '@core/referential/device-family.enum'
import { WaterType } from '@core/referential/water-type.enum'
import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import z from 'zod'

export const GetEquipmentPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
  equipmentId: z.string().uuid(),
})

export const GetEquipmentQueryStringSchema = z.object({
  action: z.union([z.literal('preventive'), z.literal('sample')]),
})

const EquipmentSchema = z.object({
  equipmentId: z.string().uuid(),
  equipmentRefId: numberOrStringToNumberSchema,
  siteId: numberOrStringToNumberSchema,
  useCase: z.string(),
  name: z.string(),
  group: z.boolean().nullable(),
  category: z.string(),
  waterNetworkId: numberOrStringToNumberSchema.nullable(),
  deviceMetadata: z
    .object({
      deviceFamily: z.nativeEnum(DeviceFamily).nullable().optional(),
    })
    .nullable()
    .optional(),
  companyId: z.number(),
  metadata: z
    .object({
      description: z.string().nullable().optional(),
      model: z.string().nullable().optional(),
      number: z.string().nullable().optional(),
      position: z.string().nullable().optional(),
    })
    .optional()
    .nullable(),
  canHaveDevice: z.boolean(),
  deviceId: z.string().uuid().nullable(),
  deviceName: z.string().nullable(),
  deviceWaterType: z.nativeEnum(WaterType).optional().nullable(),
  description: z.string().optional().nullable(),
  disabled: z.boolean().optional().nullable(),
  hasAction: z.boolean(),
})

const LocationsSchema = z.object({
  buildingId: numberOrStringToNumberSchema.optional(),
  floorId: numberOrStringToNumberSchema.optional(),
  spaceId: numberOrStringToNumberSchema.optional(),
})

const FilesSchema = z.array(
  z.object({
    id: z.string().uuid(),
    name: z.string(),
    type: z.string(),
    link: z.string(),
    equipmentId: z.string().uuid().nullable().optional(),
    date: z.string().optional(),
  }),
)

export const GetEquipmentResponseSchema = EquipmentSchema.merge(
  z.object({
    files: FilesSchema,
    locations: LocationsSchema,
  }),
)
export type GetEquipmentPathParameter = z.infer<typeof GetEquipmentPathParameterSchema>
export type GetEquipmentResponse = z.input<typeof GetEquipmentResponseSchema>
export type GetEquipmentQueryString = z.input<typeof GetEquipmentQueryStringSchema>
