import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { AlertTriangleIcon, MailIcon } from 'lucide-react'
import { type BaseSyntheticEvent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { z } from 'zod'

import RequireNoAuth from '@/components/auth/require-no-auth'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import Loader from '@/components/ui/loader'
import { PasswordInput } from '@/components/ui/password-input'
import { useAuth } from '@/hooks/use-auth'
import type { TranslationKeys } from '@/i18n/translation-shape'
import { OuiSenseIcon } from '@/icons'

export const Route = createFileRoute('/_auth/login')({
  component: () => (
    <RequireNoAuth>
      <Login />
    </RequireNoAuth>
  ),
  validateSearch(search: { redirect?: string; fromReset?: true; email?: string }) {
    return z
      .object({
        redirect: z.string().optional(),
        fromReset: z.literal(true).optional(),
        email: z.string().email().optional(),
      })
      .parse(search)
  },
})

const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(2),
  remember: z.boolean(),
})

function useLogin() {
  return useMutation<unknown, { title: TranslationKeys; text: TranslationKeys } | Error, z.infer<typeof LoginSchema>>({
    async mutationFn(values) {
      const result = LoginSchema.safeParse(values)

      if (!result.success) {
        throw {
          title: 'login.error.title',
          text: 'login.error.badUserBadPassword',
        }
      }

      const { email, password, remember } = result.data

      const { login } = useAuth.getState()

      try {
        await login(email, password, remember)
      } catch (error) {
        const { name } = error as Error

        switch (name) {
          case 'UserAlreadyAuthenticatedException': {
            throw {
              title: 'login.error.title',
              text: 'login.error.userAlreadyAuthenticated',
            }
          }

          case 'NotAuthorizedException': {
            throw {
              title: 'login.error.title',
              text: 'login.error.badUserBadPassword',
            }
          }

          default: {
            throw {
              title: 'login.error.title',
              text: 'error.genericError',
            }
          }
        }
      }
    },
  })
}

function Login() {
  const login = useLogin()
  const { t } = useTranslation()
  const search = Route.useSearch()
  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: search.email ?? '',
      password: '',
      remember: false,
    },
  })

  const loading = login.isPending
  const hasError = login.isError

  useEffect(() => {
    let id: number | string | undefined

    if (search.fromReset) {
      id = toast.success(t('resetPasswordSuccess'))
    }

    return () => {
      if (id !== undefined) {
        setTimeout(() => {
          toast.dismiss(id)
        }, 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values: z.infer<typeof LoginSchema>, evt?: BaseSyntheticEvent) => {
    if (evt) {
      await login.mutateAsync(values)
    }
  }

  return (
    <div className="container mx-auto flex h-dvh max-w-xl flex-col items-center justify-center gap-4 px-6 py-8 sm:gap-6">
      <div className="flex flex-col items-center gap-6 self-stretch">
        <OuiSenseIcon fill={hasError ? 'rgb(239, 68, 68)' : undefined} />
        <h1 className="text-xl font-semibold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">{t('login.title')}</h1>
      </div>
      <Card className="w-full">
        <CardContent className="pt-6">
          <Form {...form}>
            <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                key="email"
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-gray-900">{t('email')}</FormLabel>
                    <div className="relative">
                      <MailIcon className="pointer-events-none absolute left-2 top-1/2 size-6 -translate-y-1/2 text-gray-400" />
                      <FormControl>
                        <Input placeholder="email@example.com" disabled={loading || field.disabled} className="pl-10" {...field} />
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('login.password')}</FormLabel>
                    <PasswordInput {...field} disabled={loading || field.disabled} />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-col items-center justify-between">
                <div className="flex items-start">
                  <FormField
                    control={form.control}
                    name="remember"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="flex items-center space-x-2">
                            <Checkbox id="remember" name="remember" disabled={loading} checked={field.value} onCheckedChange={field.onChange} />
                            <Label htmlFor="remember" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                              {t('login.rememberMe')}
                            </Label>
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <Button type="submit" disabled={loading} className="space-x-4 px-6">
                  <span>{t('login.submit')}</span>
                  {loading && <Loader className="size-5" fill="#ffffff" />}
                </Button>
              </div>
              {login.isError && (
                <Alert variant="destructive">
                  <AlertTriangleIcon className="size-4" />
                  {login.error instanceof Error ? (
                    <AlertTitle>{login.error.message}</AlertTitle>
                  ) : (
                    <>
                      <AlertTitle>{t(login.error.title)}</AlertTitle>
                      <AlertDescription>{t(login.error.text)}</AlertDescription>
                    </>
                  )}
                </Alert>
              )}
            </form>
          </Form>
        </CardContent>
      </Card>
      <Button asChild variant="link" size="sm">
        <Link to="/reset-password" search={{ step: 'email' }}>
          <span>{t('resetPassword')}</span>
        </Link>
      </Button>
    </div>
  )
}
