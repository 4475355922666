import { Navigate } from '@tanstack/react-router'
import { type PropsWithChildren } from 'react'

import { useAuth } from '@/hooks/use-auth'

export default function RequireNoAuth({ children }: PropsWithChildren) {
  const user = useAuth((state) => state.user)

  if (user) {
    return <Navigate replace to="/" />
  }

  return children
}
