import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/site/$id/')({
  component: SiteId,
})

function SiteId() {
  const id = Route.useParams({ select: (search) => search.id })

  return <Navigate to="/site/$id/equipment" params={{ id }} search={{ tab: 'preventive' }} />
}
