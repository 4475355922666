import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router'
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth'
import { t } from 'i18next'
import { AlertTriangleIcon, ArrowLeftIcon, EyeIcon, EyeOffIcon, KeyRoundIcon, MailIcon, ShieldIcon } from 'lucide-react'
import { type BaseSyntheticEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import RequireNoAuth from '@/components/auth/require-no-auth'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import Loader from '@/components/ui/loader'
import { OuiSenseIcon } from '@/icons'

export const Route = createFileRoute('/_auth/reset-password')({
  component: () => (
    <RequireNoAuth>
      <ResetPassword />
    </RequireNoAuth>
  ),
  validateSearch(search: { step?: 'code' | 'email'; delivery: string }) {
    return z
      .object({
        step: z.union([z.literal('code'), z.literal('email')]).default('email'),
        delivery: z.literal('email').optional(),
        email: z.string().email().optional(),
      })
      .superRefine(({ step, delivery, email }, ctx) => {
        if (step === 'code' && delivery === 'email' && !email) {
          ctx.addIssue({
            code: 'invalid_string',
            message: 'Email is required when delivery email and step code',
            path: ['email'],
            validation: 'email',
          })
        }
      })
      .parse(search)
  },
})

const ResetPasswordSchema = z.object({
  verificationCode: z.string().length(6),
  newPassword: z
    .string()
    .min(8)
    .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/, t('passwordComplexityError')),
  passwordConfirm: z
    .string()
    .min(8)
    .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/, t('passwordComplexityError')),
})

const EmailFormSchema = z.object({
  email: z.string().email().min(1),
})

function useResetPassword() {
  return useMutation({
    async mutationFn(values: z.infer<typeof EmailFormSchema>) {
      const res = await resetPassword({
        username: values.email,
      })

      if (res.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        return { nextStep: { delivery: res.nextStep.codeDeliveryDetails.deliveryMedium, email: res.nextStep.codeDeliveryDetails.destination } }
      }

      return {
        done: true,
      }
    },
  })
}

function useConfirmResetPassword() {
  return useMutation({
    async mutationFn(values: z.infer<typeof ResetPasswordSchema> & { email: string }) {
      await confirmResetPassword({
        username: values.email,
        newPassword: values.newPassword,
        confirmationCode: values.verificationCode,
      })
    },
  })
}

function ResetPassword() {
  const { t } = useTranslation()
  const resetPassword = useResetPassword()
  const confirmResetPassword = useConfirmResetPassword()
  const emailForm = useForm<z.infer<typeof EmailFormSchema>>({
    resolver: zodResolver(EmailFormSchema),
    values: {
      email: '',
    },
  })
  const form = useForm<z.infer<typeof ResetPasswordSchema>>({
    resolver: zodResolver(ResetPasswordSchema),
    values: {
      verificationCode: '',
      newPassword: '',
      passwordConfirm: '',
    },
  })
  const { step, delivery, email: defaultEmail } = Route.useSearch()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const email = {
    isPending: resetPassword.isPending,
    hasError: resetPassword.isError,
  }

  const code = {
    isPending: confirmResetPassword.isPending,
    hasError: confirmResetPassword.isError,
  }

  const onSubmit = async (values: z.infer<typeof ResetPasswordSchema> | z.infer<typeof EmailFormSchema>, evt?: BaseSyntheticEvent) => {
    if (evt) {
      if ('email' in values) {
        const res = await resetPassword.mutateAsync(values)

        if (res.done) {
          await navigate({
            to: '/login',
          })
        } else {
          await navigate({
            to: '/reset-password',
            search: {
              step: 'code',
              delivery: (res.nextStep?.delivery?.toLowerCase() as 'email') /* faking typescript about string */ ?? 'email',
              email: values.email,
            },
          })
        }
      } else {
        await confirmResetPassword.mutateAsync({ ...values, email: emailForm.getValues().email })
        await navigate({
          to: '/login',
          search: {
            email: defaultEmail,
            fromReset: true,
          },
        })
      }
    }
  }

  return (
    <div className="container mx-auto flex h-dvh max-w-xl flex-col items-center justify-center gap-4 px-6 py-8 sm:gap-6">
      <div className="flex flex-col items-center gap-6 self-stretch">
        <OuiSenseIcon fill={email.hasError || code.hasError ? 'rgb(239, 68, 68)' : undefined} />
        <h1 className="text-xl font-semibold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
          {step === 'code' && delivery === 'email' ? t('useYourCodeByEmail') : t('resetPassword')}
        </h1>
      </div>
      <Card className="relative w-full">
        <CardContent className="pt-6">
          {step === 'code' && delivery === 'email' ? (
            <Form {...form}>
              <form className="flex flex-col space-y-4 md:space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
                <FormField
                  key="code"
                  control={form.control}
                  name="verificationCode"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('verificationCode')}</FormLabel>
                      <div className="relative">
                        <ShieldIcon className="pointer-events-none absolute left-2 top-1/2 size-6 -translate-y-1/2 text-gray-400" />
                        <FormControl>
                          <Input type="text" className="pl-10" disabled={code.isPending || field.disabled} minLength={6} maxLength={6} {...field} />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="newPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('login.password')}</FormLabel>
                      <div className="relative">
                        <KeyRoundIcon className="pointer-events-none absolute left-2 top-1/2 size-6 -translate-y-1/2 text-gray-400" />
                        <FormControl>
                          <Input
                            placeholder={t('login.passwordPlaceHolder')}
                            type={showPassword ? 'text' : 'password'}
                            className="px-10 placeholder:text-gray-400"
                            disabled={code.isPending || field.disabled}
                            {...field}
                          />
                        </FormControl>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="absolute right-2 top-1/2 size-7 -translate-y-1/2 text-gray-400"
                          tabIndex={-1}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <EyeOffIcon className="size-6" /> : <EyeIcon className="size-6" />}
                        </Button>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="passwordConfirm"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('login.passwordConfirm')}</FormLabel>
                      <div className="relative">
                        <KeyRoundIcon className="pointer-events-none absolute left-2 top-1/2 size-6 -translate-y-1/2 text-gray-400" />
                        <FormControl>
                          <Input
                            placeholder={t('login.passwordPlaceHolder')}
                            type={showPassword ? 'text' : 'password'}
                            className="px-10 placeholder:text-gray-400"
                            disabled={code.isPending || field.disabled}
                            {...field}
                          />
                        </FormControl>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="absolute right-2 top-1/2 size-7 -translate-y-1/2 text-gray-400"
                          tabIndex={-1}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <EyeOffIcon className="size-6" /> : <EyeIcon className="size-6" />}
                        </Button>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex items-center justify-center">
                  <Button type="submit" disabled={code.isPending} className="space-x-4 px-6">
                    <span>{t('common.button.validate')}</span>
                    {code.isPending && <Loader className="size-5" fill="#ffffff" />}
                  </Button>
                </div>
              </form>

              <Button
                className="absolute bottom-2 left-2"
                size="icon"
                variant="ghost"
                disabled={confirmResetPassword.isPending}
                onClick={() => {
                  resetPassword.reset()
                  void navigate({ to: '/reset-password', search: { step: 'email' } })
                }}
              >
                <ArrowLeftIcon className="text-gray-00 size-6" />
              </Button>
            </Form>
          ) : (
            <Form {...emailForm}>
              <form className="flex flex-col space-y-4" onSubmit={emailForm.handleSubmit(onSubmit)}>
                <FormField
                  key="email"
                  control={emailForm.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-900">{t('email')}</FormLabel>
                      <div className="relative">
                        <MailIcon className="pointer-events-none absolute left-2 top-1/2 size-6 -translate-y-1/2 text-gray-400" />
                        <FormControl>
                          <Input placeholder="email@example.com" disabled={email.isPending || field.disabled} className="pl-10" {...field} />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button type="submit" disabled={email.isPending} className="space-x-4 self-center px-6">
                  <span>{t('common.button.validate')}</span>
                  {email.isPending && <Loader className="size-5" fill="#ffffff" />}
                </Button>

                {email.hasError && (
                  <Alert>
                    <AlertTriangleIcon className="size-4" />
                    <AlertTitle>{t('error.genericError')}</AlertTitle>
                    {resetPassword.error !== null && (
                      <AlertDescription>
                        {'__type' in resetPassword.error && resetPassword.error.__type === 'LimitExceededException' && t('rateLimitError')}
                      </AlertDescription>
                    )}
                  </Alert>
                )}
              </form>
            </Form>
          )}
        </CardContent>
      </Card>
      <Button asChild variant="link" size="sm">
        <Link to="/login">{t('backToLogin')}</Link>
      </Button>
    </div>
  )
}
