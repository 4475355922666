import { createFileRoute, Outlet, useRouterState } from '@tanstack/react-router'

import { FullScreenLoader } from '@/components/ui/full-screen-loader'

export const Route = createFileRoute('/_auth')({
  component: AuthLayout,
})

function AuthLayout() {
  const { isLoading } = useRouterState()

  return (
    <main className="bg-gray dark:bg-gray-900">
      {isLoading && <FullScreenLoader />}
      <Outlet />
    </main>
  )
}
