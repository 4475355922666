export enum DTDeviceType {
  TOUCH = 'touch',
  TEMPERATURE = 'temperature',
  HUMIDITY = 'humidity',
  PROXIMITY = 'proximity',
  PROXIMITY_COUNTER = 'proximityCounter',
  MOTION = 'motion',
  CO2 = 'co2',
  DESK_OCCUPANCY = 'deskOccupancy',
  TOUCH_COUNTER = 'touchCounter',
  WATER_DETECTOR = 'waterDetector',
  CLOUD_CONNECTOR = 'ccon',
}
