import { GetAlertCommentResponseSchema } from '@shared/schema/alert.get-comment.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function alertCommentsQuery(alertId: string) {
  return queryOptions({
    queryKey: ['alert', 'comments', alertId],
    async queryFn() {
      const rawResponse = await apiFetch.get(`alert/${alertId}/comment`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch alert comments for alert ${alertId}`)
      }

      try {
        return await GetAlertCommentResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse alert comments response', { cause: error })
      }
    },
  })
}
