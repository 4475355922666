import { GetEquipmentWithActionsOrSamplesResponseSchema } from '@shared/schema/equipment.with-actions-or-samples.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function equipmentsWithSamplingsQuery(siteId: number, { action }: { action: 'hide' | 'show' }) {
  return queryOptions({
    queryKey: ['site', siteId, 'equipment', 'with-samples', { action }],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/equipment/list-with-samples?action=${action}`)

      if (!rawResponse.ok) {
        throw new Error('Failed to fetch equipments with samples')
      }

      try {
        return await GetEquipmentWithActionsOrSamplesResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse equipments with samples response', { cause: error })
      }
    },
  })
}
