import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetEquipmentRefResponseSchema = z.array(
  z.object({
    id: numberOrStringToNumberSchema,
    name: z.string(),
    category: z.string(),
    group: z.boolean(),
  }),
)

export type GetEquipmentRefResponse = z.infer<typeof GetEquipmentRefResponseSchema>
export type EquipmentRef = GetEquipmentRefResponse[number]
