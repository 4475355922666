import { GetNoConformityByIdActionReadingResponseSchema } from '@shared/schema/action-reading.get-no-conformity-by-id.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function actionNoConformityQuery(siteId: number, actionReadingId: string) {
  return queryOptions({
    queryKey: ['site', siteId, 'action-no-conformity', actionReadingId],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/action/no-conformity/detail/${actionReadingId}`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch action no-conformity ${siteId}-${actionReadingId}`)
      }

      try {
        return await GetNoConformityByIdActionReadingResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse action no-conformity response', { cause: error })
      }
    },
  })
}
