import { GetActionByUserResponseSchema } from '@shared/schema/user.action.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export const userActionsQuery = queryOptions({
  queryKey: ['user', 'actions'],
  async queryFn() {
    const rawResponse = await apiFetch.get('user/actions')

    if (!rawResponse.ok) {
      throw new Error('Failed to fetch user actions')
    }

    try {
      return await GetActionByUserResponseSchema.parseAsync(await rawResponse.json())
    } catch (error) {
      throw new Error('Failed to parse user actions response', { cause: error })
    }
  },
})
