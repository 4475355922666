export enum FileType {
  LEGIO_SCHEMATIC = 'legioSchematic',
  LEGIO_WATER_DIAG = 'legioWaterDiag',
  LEGIO_CONTRACT_LAB = 'legioContractLab',
  LEGIO_CONTRACT_LAB_2 = 'legioContractLab2',
  LEGIO_CONTRACT_LAB_3 = 'legioContractLab3',
  LEGIO_CONTRACT_MAINTENANCE = 'legioContractMaintenance',
  LEGIO_CONTRACT = 'legioContract',
  LEGIO_CONTRACT_WATER_TREATMENT = 'legioContractWaterTreatment',
  LEGIO_REGULATION = 'legioRegulation',
  LEGIO_WORKS = 'legioWorks',
  LEGIO_SAMPLE = 'legioSample',
  PHOTO_EQUIPMENT = 'photoEquipment',
  PHOTO_COMPANY = 'photoCompany',
  PHOTO_SITE = 'photoSite',
  LEGIO_WATER_SOURCE = 'legioWaterSource',
  LEGIO_OTHER_INSTALLATION = 'legioOtherInstallation',
  LEGIO_OTHER_AUDIT = 'legioOtherAudit',
  LEGIO_INCIDENT = 'legioIncident',
  REPORT = 'report',
}
