import { type RouterState, useRouterState } from '@tanstack/react-router'

export function useLocation<T extends RouterState['location'], TSelected = RouterState['location']>({
  select,
}: {
  select?: (state: T) => TSelected
} = {}): TSelected {
  return useRouterState({
    select(state) {
      return select
        ? // @ts-expect-error - deep type error ??
          select(state.location)
        : state.location
    },
  }) as TSelected
}
