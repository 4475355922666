import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { actionReadingQuery } from '@/queries/action-reading.query'

export const Route = createFileRoute('/_dashboard/site/$id/actions-done/$actionId/')({
  loader({ params, context: { queryClient } }) {
    return queryClient.ensureQueryData(actionReadingQuery(params.id, params.actionId))
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
