import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { parseDateRange } from '@/lib/date'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'
import { reportConfigurationsQuery } from '@/queries/report-configuration.query'
import { reportsQuery } from '@/queries/report.query'

const defaultDateRange = parseDateRange({
  startDate: dayjs().subtract(30, 'day').toDate(),
  endDate: dayjs().toDate(),
})

export const SearchSchema = z.object({
  tab: z.union([z.literal('reports'), z.literal('report-configurations')]).default('reports'),
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/site/$id/reports/')({
  validateSearch: SearchSchema,
  beforeLoad({ search, context }) {
    if (search.tab === 'report-configurations' && !context.user?.hasPermission(Permission.REPORT_CONFIGURATION)) {
      throw redirect({
        to: '/403',
      })
    }

    if (isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  loaderDeps({ search }) {
    return search
  },
  loader({ params, context: { queryClient }, deps: { tab, startDate, endDate } }) {
    if (tab === 'reports') {
      void queryClient.ensureQueryData(reportsQuery({ siteId: params.id, dateRange: { startDate, endDate } }))
    }

    if (tab === 'report-configurations') {
      void queryClient.ensureQueryData(reportConfigurationsQuery(params.id))
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
