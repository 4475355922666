import { AlertType } from '@core/referential/alert-type.enum'
import { DeviceFamily } from '@core/referential/device-family.enum'
import { DeviceSubType } from '@core/referential/device-sub-type.enum'
import { DeviceType } from '@core/referential/device-type.enum'
import { DTEventType } from '@core/referential/dt-event-type.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetDevicePathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetDevicesResponseSchema = z.array(
  z.object({
    id: z.string().uuid(),
    name: z.string().nullable(),
    vendorDeviceId: z.string(),
    vendorShortName: z.string().nullable(),
    equipmentId: z.string().uuid().nullable(),
    equipmentName: z.string().optional().nullable(),
    description: z.string().nullable().optional(),
    timezone: z.string().optional().nullable(),
    tempOffsetCelsius: z.number().optional().nullable(),
    uncertainty: z.number().optional().nullable(),
    nonCoreMetadata: z
      .object({
        sensorSignalStrengh: z.number(),
        sensorTransmissionMode: z.string(),
        sensorConnectedToCloudConnector: z.string(),
        lastSensorUpdatedTime: z.string(),
        sensorBatteryPercentage: z.number(),
        cloudConnectorConnectionStatus: z.string(),
        cloudConnectorCellularSignalStrength: z.number(),
      })
      .partial()
      .nullable(),
    metadata: z
      .object({
        deviceFamily: z.nativeEnum(DeviceFamily),
        eventType: z.array(z.nativeEnum(DTEventType)),
        useCase: z.array(z.nativeEnum(UseCase)),
        type: z.nativeEnum(DeviceType),
        subType: z.nativeEnum(DeviceSubType),
        threshold: z
          .object({
            coldStorage: z.array(z.number()),
            legionella: z.array(z.number()),
          })
          .partial()
          .optional(),
        waterMoveDay: z.number().nullable().optional(),
        excursionMinute: z
          .object({
            coldStorage: z.number(),
          })
          .partial()
          .nullable()
          .optional(),
        alertType: z.array(z.nativeEnum(AlertType)),
      })
      .partial()
      .nullable(),
    building: z.string().optional().nullable(),
    buildingId: numberOrStringToNumberSchema.optional().nullable(),
    floor: z.string().optional().nullable(),
    floorId: numberOrStringToNumberSchema.optional().nullable(),
    space: z.string().optional().nullable(),
    spaceId: numberOrStringToNumberSchema.optional().nullable(),
  }),
)

export const GetDevicesQueryStringSchema = z
  .object({
    eventType: z.nativeEnum(DTEventType).optional(),
    deviceFamily: z.nativeEnum(DeviceFamily).optional().nullable(),
  })
  .optional()

export type GetDevicesPathParameter = z.infer<typeof GetDevicePathParameterSchema>
export type GetDevicesResponse = z.infer<typeof GetDevicesResponseSchema>
export type GetDevicesQueryString = z.infer<typeof GetDevicesQueryStringSchema>
