import { DTEventType } from '@core/referential/dt-event-type.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { parseDateRange } from '@/lib/date'
import { availableEventTypes } from '@/lib/event-type'

const defaultDateRange = parseDateRange({
  startDate: dayjs().subtract(3, 'day').toDate(),
  endDate: dayjs().toDate(),
})

const SearchSchema = z.object({
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
  eventType: z.nativeEnum(DTEventType).optional(),
})

export const Route = createFileRoute('/_dashboard/site/$id/monitoring/$deviceId/')({
  validateSearch: SearchSchema,
  beforeLoad({ params, search }) {
    if (search.eventType && !availableEventTypes.includes(search.eventType)) {
      throw redirect({ to: '/site/$id/monitoring/$deviceId/', params, search: { ...search, eventType: DTEventType.TEMPERATURE } })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
