import { createFileRoute } from '@tanstack/react-router'
import { Building2Icon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { OuiSenseIcon } from '@/icons'

const SearchSchema = z.object({
  reason: z.union([z.literal('noSites'), z.literal('wrongUseCase'), z.literal('')]).optional(),
})

export const Route = createFileRoute('/403/')({
  component: ForbiddenLayout,
  validateSearch(search) {
    return SearchSchema.parse(search)
  },
})

function ForbiddenLayout() {
  const { t } = useTranslation()
  const search = Route.useSearch()

  return (
    <div id="error-page">
      <section className="bg-gray dark:bg-gray-900">
        <div className="mx-auto flex h-screen flex-col items-center justify-center px-6 py-8 sm:h-screen md:h-screen lg:py-0">
          <div className="flex flex-col items-center gap-6 self-stretch">
            {search.reason === 'noSites' ? <Building2Icon className="size-20" stroke="red" /> : <OuiSenseIcon fill="red" />}
            <h1 className="text-xl font-semibold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">403</h1>
          </div>
          <p>{search.reason === 'noSites' ? t('noSitesAssigned') : t('accessForbidden')}</p>
          {search.reason === 'wrongUseCase' ? null : <p>{t('requestAdmin')}</p>}
        </div>
      </section>
    </div>
  )
}
