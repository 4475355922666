import { numberOrStringSchema, numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetEquipmentWithActionsOrSamplesPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetEquipmentWithActionsOrSamplesQueryStringSchema = z.object({
  action: z.union([z.literal('hide'), z.literal('show')]),
})

export const BaseEquipmentsActionsSchema = z.object({
  id: numberOrStringSchema.nullable(),
  name: z.string().optional().nullable(),
  equipmentId: z.string().optional().nullable(),
  equipmentName: z.string().optional().nullable(),
  deviceId: z.string().optional().nullable(),
  deviceName: z.string().optional().nullable(),
  actions: z
    .array(
      z.object({
        id: z.string(),
        frequency: z.string(),
        category: z.string(),
        name: z.string(),
        type: z.string(),
      }),
    )
    .optional()
    .nullable(),
  category: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
})
type EquipmentsWithAction = z.infer<typeof BaseEquipmentsActionsSchema> & {
  children?: Array<EquipmentsWithAction>
}

export const EquipmentsActionsSchema: z.ZodType<EquipmentsWithAction> = BaseEquipmentsActionsSchema.extend({
  children: z.lazy(() => EquipmentsActionsSchema.array()).optional(),
})

export const GetEquipmentWithActionsOrSamplesResponseSchema = z.object({
  treeSite: EquipmentsActionsSchema.array(),
})

export type GetEquipmentWithActionsOrSamplesResponse = z.infer<typeof GetEquipmentWithActionsOrSamplesResponseSchema>
export type EquipmentsActions = z.infer<typeof EquipmentsActionsSchema>
export type GetEquipmentWithActionsOrSamplesPathParameter = z.infer<typeof GetEquipmentWithActionsOrSamplesPathParameterSchema>
export type GetEquipmentWithActionsOrSamplesQueryString = z.infer<typeof GetEquipmentWithActionsOrSamplesQueryStringSchema>
