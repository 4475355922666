import { GetEquipmentResponseSchema } from '@shared/schema/equipment.get.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function equipmentQuery(siteId: number, equipmentId: string, { action }: { action: 'preventive' | 'sample' }) {
  return queryOptions({
    queryKey: ['site', siteId, 'equipment', equipmentId, { action }],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/equipment/${equipmentId}?action=${action}`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch equipment ${siteId}`)
      }

      try {
        return await GetEquipmentResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse equipment response', { cause: error })
      }
    },
  })
}
