import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetExistingActionSamplePathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetExistingActionSampleResponseSchema = z.array(
  z.object({
    name: z.string(),
    type: z.string(),
  }),
)

export type GetExistingActionSampleResponse = z.infer<typeof GetExistingActionSampleResponseSchema>
export type GetExistingActionSamplePathParameter = z.infer<typeof GetExistingActionSamplePathParameterSchema>
