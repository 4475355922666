import { GetEquipmentRefResponseSchema } from '@shared/schema/equipment-ref.get.schema'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export const equipmentRefsSamplesQuery = queryOptions({
  queryKey: ['equipment-refs', 'samples'],
  async queryFn() {
    const rawResponse = await apiFetch.get('equipment-ref/sample')

    if (!rawResponse.ok) {
      throw new Error('Failed to fetch equipment refs with samples')
    }

    try {
      return await GetEquipmentRefResponseSchema.parseAsync(await rawResponse.json())
    } catch (error) {
      throw new Error('Failed to parse equipment refs with samples', { cause: error })
    }
  },
  staleTime: 1000 * 60 * 60 * 24, // 24 hours
})
