import { LocationType } from '@core/referential/location-type.enum'
import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetLocationQueryStringParametersSchema = z.object({
  type: z.nativeEnum(LocationType).optional(),
  parentId: numberOrStringToNumberSchema.optional(),
})

export const GetLocationPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetLocationSchemaResponse = z.array(
  z.object({
    id: numberOrStringToNumberSchema,
    companyId: z.number(),
    createdAt: z.union([z.date(), z.string().trim()]),
    deletedAt: z.union([z.date(), z.string().trim()]).optional().nullable(),
    name: z.string().trim(),
    type: z.nativeEnum(LocationType),
    parentId: numberOrStringToNumberSchema.nullable().optional(),
    siteId: numberOrStringToNumberSchema,
  }),
)

export type GetLocationQueryStringParameters = z.infer<typeof GetLocationQueryStringParametersSchema>
export type GetLocationPathParameter = z.infer<typeof GetLocationPathParameterSchema>
export type GetLocationResponse = z.infer<typeof GetLocationSchemaResponse>
