import { z } from 'zod'

export const numberOrStringSchema = z.union([z.number(), z.string().trim()])
export const numberOrStringToNumberSchema = numberOrStringSchema.transform((v, ctx) => {
  const parsed = Number.parseInt(v.toString(), 10)
  if (Number.isNaN(parsed)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Not a number',
    })

    return z.NEVER
  }

  return parsed
})

export const numberOrStringToNumberFloatSchema = numberOrStringSchema.transform((v, ctx) => {
  const parsed = Number.parseFloat(v.toString())
  if (Number.isNaN(parsed)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Not a number',
    })

    return z.NEVER
  }

  return parsed
})

const orAndSchema = z.union([
  z.tuple([
    z.object({
      symbol: z.string(),
      value: z.number(),
    }),
    z.object({
      symbol: z.string(),
      value: z.number(),
    }),
  ]),
  z.tuple([
    z.object({
      symbol: z.string(),
      value: z.number(),
    }),
  ]),
])

export const conditionSchema = z.object({
  and: orAndSchema.optional(),
  or: orAndSchema.optional(),
  durationMinutes: z.number().optional(),
  forceConformity: z.boolean().optional(),
})

export const dateSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/)
