import { EyeIcon, EyeOffIcon, KeyRoundIcon } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from './button'
import { FormControl } from './form'
import { Input, type InputProps } from './input'

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="relative">
      <KeyRoundIcon className="pointer-events-none absolute left-2 top-1/2 size-6 -translate-y-1/2 text-gray-400" />
      <FormControl>
        <Input
          ref={ref}
          placeholder={t('login.passwordPlaceHolder')}
          type={showPassword ? 'text' : 'password'}
          className="px-10 placeholder:text-gray-400"
          {...props}
        />
      </FormControl>
      <Button
        variant="ghost"
        size="icon"
        className="absolute right-2 top-1/2 size-7 -translate-y-1/2 text-gray-400"
        tabIndex={-1}
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <EyeOffIcon className="size-6" /> : <EyeIcon className="size-6" />}
      </Button>
    </div>
  )
})

PasswordInput.displayName = 'PasswordInput'

export { PasswordInput }
