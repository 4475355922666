import { ActionCategory } from '@core/referential/action-category.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { ActionStatus } from '@shared/types/action-status'
import { createFileRoute } from '@tanstack/react-router'
import { redirect } from '@tanstack/react-router'
import { z } from 'zod'

import { isUseCase } from '@/lib/utils'

export const Route = createFileRoute('/_dashboard/site/$id/my-actions/')({
  beforeLoad() {
    if (!isUseCase(UseCase.LEGIONELLA)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  parseParams(params) {
    return z
      .object({
        id: numberOrStringToNumberSchema,
      })
      .parse(params)
  },
  validateSearch(search: unknown) {
    return z
      .object({
        building: z.union([z.literal(-1), numberOrStringToNumberSchema]).default(-1),
        floor: z.union([z.literal(-1), numberOrStringToNumberSchema]).default(-1),
        status: z.nativeEnum(ActionStatus).nullable().default(null),
        category: z.nativeEnum(ActionCategory).nullable().default(null),
      })
      .parse(search)
  },
})
