import { ReportFrequency } from '@core/referential/frequency.enum'
import { dateSchema, numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetAllReportPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetAllReportQueryStringSchema = z.object({
  startDate: dateSchema,
  endDate: dateSchema,
})

export const GetAllReportResponseSchema = z.array(
  z.object({
    id: z.string(),
    frequency: z.nativeEnum(ReportFrequency),
    name: z.string().optional(),
    startDate: z.union([z.date(), z.string().trim()]),
    fileId: z.string().uuid(),
  }),
)

export type GetAllReportPathParameter = z.infer<typeof GetAllReportPathParameterSchema>
export type GetAllReportQueryString = z.infer<typeof GetAllReportQueryStringSchema>
export type GetAllReportResponse = z.infer<typeof GetAllReportResponseSchema>
