import { Role } from '@core/referential/role.enum'

export enum Permission {
  PATRIMONY_SITES = 'patrimony_sites',
  PATRIMONY_USERS = 'patrimony_users',
  SITE_GENERAL = 'site_general',
  ALERT_CONFIGURATION = 'alert-configuration',
  REPORT_CONFIGURATION = 'report_configuration',
  SITE_INFORMATION = 'site_information',
  SITE_EQUIPMENT = 'site_equipment',
  SITE_ACTION_ASSIGNATION = 'site_action_assignation',
  SITE_DEVICES = 'site_devices',
  SITE_DASHBOARD = 'site_dashboard',
}

export function userPermission(role: Role, permission: Permission): boolean {
  switch (permission) {
    case Permission.PATRIMONY_SITES: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    case Permission.PATRIMONY_USERS: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    case Permission.SITE_GENERAL: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    case Permission.ALERT_CONFIGURATION: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    case Permission.REPORT_CONFIGURATION: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    case Permission.SITE_INFORMATION: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    case Permission.SITE_ACTION_ASSIGNATION: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    case Permission.SITE_EQUIPMENT: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    case Permission.SITE_DEVICES: {
      return role === Role.ADMIN || role === Role.MANAGER
    }

    default: {
      return false
    }
  }
}
