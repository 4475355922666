import { GetAllReportResponseSchema } from '@shared/schema/reporting.get-all.schema.'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { type SiteId } from '@/@types/site'
import { apiFetch } from '@/lib/api-fetch'
import { type DateRangeString } from '@/lib/date'

type Params = {
  siteId: SiteId
  dateRange: DateRangeString
}

export function reportsQuery(params: Params) {
  return queryOptions({
    queryKey: ['site', params.siteId, 'reports', params.dateRange],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${params.siteId}/report`, {
        searchParams: params.dateRange,
      })
      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch reports for site ${params.siteId}`)
      }

      try {
        return await GetAllReportResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse reports response', { cause: error })
      }
    },
  })
}

export type Report = QueryOptionsData<typeof reportsQuery>[number]
