import { DTEventType } from '@core/referential/dt-event-type.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { parseDateRange } from '@/lib/date'
import { isUseCase } from '@/lib/utils'

const defaultDateRange = parseDateRange({
  startDate: dayjs().subtract(3, 'day').toDate(),
  endDate: dayjs().toDate(),
})

export const SearchSchema = z.object({
  tab: z.union([z.literal('daily-monitoring'), z.literal('charts')]).default('daily-monitoring'),
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
  eventType: z.nativeEnum(DTEventType).default(DTEventType.TEMPERATURE),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/site/$id/monitoring/')({
  validateSearch: SearchSchema,
  beforeLoad() {
    if (isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
