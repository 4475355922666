import { GetByIdActionReadingResponseSchema } from '@shared/schema/action-reading.get-by-id.schema'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { apiFetch } from '@/lib/api-fetch'

export function actionReadingQuery(siteId: number, actionReadingId: string) {
  return queryOptions({
    queryKey: ['site', siteId, 'action-reading', actionReadingId],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/action/detail/${actionReadingId}`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch action reading ${siteId}-${actionReadingId}`)
      }

      try {
        return await GetByIdActionReadingResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse action reading response', { cause: error })
      }
    },
  })
}

export type ActionReading = QueryOptionsData<typeof actionReadingQuery>
