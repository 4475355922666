import { useTranslation } from 'react-i18next'

import { OuiSenseIcon } from '@/icons'

export default function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <div id="error-page">
      <section className="bg-gray dark:bg-gray-900">
        <div className="mx-auto flex h-screen flex-col items-center justify-center px-6 py-8 sm:h-screen md:h-screen lg:py-0">
          <div className="flex flex-col items-center gap-6 self-stretch">
            <OuiSenseIcon fill="red" />
            <h1 className="text-xl font-semibold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">{t('error.title')}</h1>
          </div>
          <h1>{t('error.description')}</h1>
        </div>
      </section>
    </div>
  )
}
