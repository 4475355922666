export enum DeviceFamily {
  TOUCH = 'touch',
  WATER = 'water',
  PROXIMITY = 'proximity',
  TEMPERATURE = 'temperature',
  AIR_QUALITY = 'airQuality',
  PIR = 'pir',
  QR_CODE = 'qrCode',
  GATEWAY = 'gateway',
  HUMIDITY = 'humidity',
  GAS = 'gas',
  ELECTRICITY = 'electricity',
  FUEL = 'fuel',
  THERMAL = 'thermal',
}
