import { GetByIdReportConfigurationResponseSchema } from '@shared/schema/reporting.get-by-id-configuration.schema'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { type SiteId } from '@/@types/site'
import { apiFetch } from '@/lib/api-fetch'

type Params = {
  siteId: SiteId
  reportParamId: string
}

export function reportConfigurationDetailsQuery({ siteId, reportParamId }: Params) {
  return queryOptions({
    queryKey: ['site', siteId, 'report-configuration', reportParamId],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/report/configuration/${reportParamId}`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch report configurations for site ${siteId}`)
      }

      try {
        return await GetByIdReportConfigurationResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse report configurations response', { cause: error })
      }
    },
  })
}

export type ReportConfiguration = QueryOptionsData<typeof reportConfigurationDetailsQuery>
