import { UseCase } from '@core/referential/use-case.enum'
import { LocationType } from '@shared/types/location-type'
import { createFileRoute } from '@tanstack/react-router'
import { redirect } from '@tanstack/react-router'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'
import { actionExistingSampleQuery } from '@/queries/action-existing-sample.query'
import { equipmentsWithActionsQuery } from '@/queries/equipments-with-actions.query'
import { equipmentsWithSamplingsQuery } from '@/queries/equipments-with-samplings.query'
import { locationsQuery } from '@/queries/locations-query'

export const SearchSchema = z.object({
  tab: z.union([z.literal('preventive'), z.literal('samplings')]).default('preventive'),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/site/$id/equipment/')({
  validateSearch: SearchSchema,
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.SITE_EQUIPMENT)) {
      throw redirect({
        to: '/403',
      })
    }

    if (isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  loaderDeps: ({ search: { tab } }) => ({ tab }),
  async loader({ params, context: { queryClient }, deps }) {
    const buildings = await queryClient.ensureQueryData(locationsQuery(params.id, LocationType.BUILDING))

    if (deps.tab === 'preventive') {
      await queryClient.ensureQueryData(equipmentsWithActionsQuery(params.id))
    } else if (deps.tab === 'samplings') {
      await queryClient.ensureQueryData(equipmentsWithSamplingsQuery(params.id, { action: 'hide' }))
      await queryClient.ensureQueryData(actionExistingSampleQuery(params.id))
    }

    return {
      buildings,
    }
  },
  pendingComponent: PendingComponent,
})
