import { GetLocationSchemaResponse } from '@shared/schema/location.get.schema'
import { type LocationType } from '@shared/types/location-type'
import { queryOptions } from '@tanstack/react-query'

import { apiFetch } from '@/lib/api-fetch'

export function locationsQuery(siteId: number, type: LocationType | null = null, parentId: number | null = null) {
  return queryOptions({
    queryKey: ['site', siteId, 'locations', type, parentId],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}/location?${type ? `type=${type}` : ''}${parentId ? `&parentId=${parentId}` : ''}`)

      if (!rawResponse.ok) {
        throw new Error('Failed to fetch locations')
      }

      try {
        return await GetLocationSchemaResponse.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse locations response', { cause: error })
      }
    },
  })
}
