import { DeviceFamily } from '@core/referential/device-family.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute } from '@tanstack/react-router'
import { redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { parseDateRange } from '@/lib/date'
import { isUseCase } from '@/lib/utils'

const defaultDateRange = parseDateRange({
  startDate: dayjs().subtract(7, 'day').toDate(),
  endDate: dayjs().toDate(),
})

export const SearchSchema = z.object({
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
  tab: z.union([z.literal('meter'), z.literal('sub-meter')]).default('meter'),
  deviceFamily: z.nativeEnum(DeviceFamily).optional(),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/energy-usage/meter-analysis/')({
  validateSearch: SearchSchema,
  beforeLoad() {
    if (!isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
