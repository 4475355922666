import { GetByIdWorkSchemaResponse } from '@shared/schema/work.get-by-id.schema'
import { queryOptions } from '@tanstack/react-query'

import { type SiteId } from '@/@types/site'
import { apiFetch } from '@/lib/api-fetch'

const fetchWork = async (siteId: SiteId, workId: string) => {
  const rawResponse = await apiFetch.get(`site/${siteId}/work/${workId}`)

  if (!rawResponse.ok) {
    throw new Error(`Failed to fetch work id ${workId}`)
  }

  try {
    return await GetByIdWorkSchemaResponse.parseAsync(await rawResponse.json())
  } catch (error) {
    throw new Error('Failed to parse work response', { cause: error })
  }
}

export function siteWorkQuery(siteId: SiteId, workId?: string, options?: { enabled?: boolean }) {
  return queryOptions({
    queryKey: ['work', siteId, workId],
    queryFn: () => (options?.enabled ? fetchWork(siteId, workId!) : null),
  })
}
