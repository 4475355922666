import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'

import { isUseCase } from '@/lib/utils'
import { actionParamsQuery } from '@/queries/action-params.query'
import { equipmentQuery } from '@/queries/equipment.query'

export const Route = createFileRoute('/_dashboard/site/$id/equipment/$equipmentId/_layout/actions')({
  async beforeLoad({ context, params, search }) {
    if (isUseCase(UseCase.COLD_STORAGE) && params.equipmentId) {
      const equipment = await context.queryClient.fetchQuery(equipmentQuery(params.id, params.equipmentId, { action: 'preventive' }))

      if (!equipment.deviceId) {
        throw redirect({
          to: '/site/$id/equipment/$equipmentId/devices',
          params,
          search,
        })
      }
    }
  },
  loaderDeps({ search: { action } }) {
    return { action }
  },
  async loader({ params, context: { queryClient }, deps: { action } }) {
    const equipment = await queryClient.ensureQueryData(equipmentQuery(params.id, params.equipmentId, { action }))
    await queryClient.ensureQueryData(actionParamsQuery(params.id, params.equipmentId, { mode: equipment.hasAction ? 'edit' : 'create', action }))
  },
})
