import { ReportFrequency } from '@core/referential/frequency.enum'
import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetByIdReportConfigurationPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
  reportParamId: z.string(),
})

export const GetByIdReportConfigurationResponseSchema = z.object({
  id: z.string(),
  actions: z.object({
    addActionSample: z.boolean(),
    addActionTemperature: z.boolean(),
    addActionWaterTreatment: z.boolean(),
    addActionPurge: z.boolean(),
    addActionMaintenance: z.boolean(),
    addActionHumidity: z.boolean(),
  }),
  addDevice: z.boolean(),
  addAlert: z.boolean(),
  frequency: z.nativeEnum(ReportFrequency),
  name: z.string().optional(),
  users: z.array(z.string().uuid()).nullable(),
  equipmentIds: z.array(z.string().uuid()),
})

export type GetByIdReportConfigurationPathParameter = z.infer<typeof GetByIdReportConfigurationPathParameterSchema>
export type GetByIdReportConfigurationResponse = z.infer<typeof GetByIdReportConfigurationResponseSchema>
