import { Frequency } from '@core/referential/frequency.enum'
import dayjs from 'dayjs'
import { type DateRange } from 'react-day-picker'

import { type DeviceEnergyFilters } from '@/@types/filter'

export type DateRangeString = {
  startDate: string
  endDate: string
}

export const dateFormat = 'YYYY-MM-DD'

export function parseDateRange({ startDate, endDate }: { startDate: Date; endDate: Date }): { startDate: string; endDate: string } {
  return {
    startDate: dayjs(startDate).format(dateFormat),
    endDate: dayjs(endDate).format(dateFormat),
  }
}

export function parseDateRangeString({ startDate, endDate }: DateRangeString): DateRange {
  return {
    from: dayjs(startDate).toDate(),
    to: dayjs(endDate).toDate(),
  }
}

export function parseDurationMinutes(durationMinutes: number): { value: number; unit: 'minutes' | 'hours' | 'days' } {
  const duration = dayjs.duration(durationMinutes, 'minutes')
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()

  if (minutes === 0) {
    if (hours === 0) {
      if (days === 0) {
        return { value: 0, unit: 'minutes' }
      }

      return { value: days, unit: 'days' }
    }

    return { value: hours, unit: 'hours' }
  }

  return { value: durationMinutes, unit: 'minutes' }
}

export function getExtremes(startTime: Date, endTime: Date | null) {
  return [dayjs(startTime).subtract(1, 'h').valueOf(), endTime ? dayjs(endTime).valueOf() : dayjs().valueOf()]
}

export function getEnergyDateRange(frequency: DeviceEnergyFilters['frequency'], currentDateRange: DateRangeString) {
  const currentStartDate = dayjs(currentDateRange.startDate)
  const currentEndDate = dayjs(currentDateRange.endDate)

  switch (frequency) {
    case Frequency.DAILY: {
      return currentDateRange
    }

    case Frequency.WEEKLY: {
      const startDate = currentStartDate.day(1).format(dateFormat)
      const endDate = (currentEndDate.day() === 0 ? currentEndDate : currentEndDate.day(0).add(1, 'week')).format(dateFormat)

      return { startDate, endDate }
    }

    case Frequency.MONTHLY: {
      return { startDate: currentStartDate.startOf('month').format(dateFormat), endDate: currentEndDate.endOf('month').format(dateFormat) }
    }

    case Frequency.YEARLY: {
      return { startDate: currentStartDate.startOf('year').format(dateFormat), endDate: currentEndDate.endOf('year').format(dateFormat) }
    }

    default: {
      return currentDateRange
    }
  }
}
