export enum ConditionType {
  TH_MIXED = 'thMixed',
  CHLORINE = 'chlorine',
  SODIUM = 'sodium',
  TH_SOFT = 'thSoft',
  TH_RAW = 'thRaw',
  PHOSPHATE = 'phosphate',
  SILICATE = 'silicate',
  CONDUCTIVITY = 'conductivity',
  GLOBAL_SAMPLE = 'globalSample',
  PH = 'ph',
  IRON = 'iron',
  TH = 'th',
  TAC = 'tac',
  TEMPERATURE = 'temperature',
  REDOX = 'redox',
  LEGIONELLA = 'legionella',
  WATER_CONSUMPTION = 'waterConsumption',
  PRESSURE = 'pressure',
  FLOW = 'flow',
  DELTA_PRESSURE = 'deltaPressure',
  MICROBIO_36 = 'microbio36',
  MICROBIO_22 = 'microbio22',
  COLI = 'coli',
  ESCHERICHIA = 'escherichia',
  ENTEROCOCCI = 'enterococci',
  PSEUDOMONAS = 'pseudomonas',
  SULFITE = 'sulfite',
  STAPHY = 'staphy',
  HUMIDITY = 'humidity',
}
