import { type Role } from '@core/referential/role.enum'
import { UsageType } from '@core/referential/usage-type.enum'
import { type UseCase } from '@core/referential/use-case.enum'
import { GetUserStateSchemaResponse } from '@shared/schema/user-state.get.schema'
import { type PutUserStatePayload } from '@shared/schema/user-state.put.schema'
import { signIn, signOut, rememberDevice, forgetDevice, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth'
import { z } from 'zod'
import { create } from 'zustand'

import { type SiteId } from '@/@types/site'
import { apiFetch } from '@/lib/api-fetch'
import { type Permission, userPermission } from '@/lib/permissions'

type UserState = { currentUseCase: UseCase; currentSiteId: SiteId | null; currentUsageType: UsageType | null }
type CurrentUser = z.infer<typeof CurrentUserSchema>
type UserAttributes = z.infer<typeof UserAttributesSchema>
export type User =
  | (UserAttributes &
      CurrentUser & {
        hasPermission: (permission: Permission) => boolean
        useCases: Array<UseCase>
        role: string
      } & UserState)
  | null

const UserAttributesSchema = z.object({
  'custom:role': z.string(),
  'custom:companyName': z.string(),
  'custom:companyId': z.string(),
  'custom:useCases': z.string(),
  email: z.string(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  email_verified: z.string().transform((v) => v === 'true'),
  name: z.string(),
})

const CurrentUserSchema = z.object({
  userId: z.string(),
})

async function getUserState(useCases: Array<UseCase>): Promise<UserState> {
  const rawResponse = await apiFetch.get('user/state')
  let { currentSiteId, currentUseCase } = GetUserStateSchemaResponse.parse(await rawResponse.json())

  if (!currentUseCase) {
    currentUseCase = useCases[0]
  }

  return { currentUseCase, currentSiteId, currentUsageType: defaultUsageType }
}

const defaultUsageType = UsageType.ENERGY

export const useAuth = create<{
  user: User | null
  login: (email: string, pwd: string, remember: boolean) => Promise<User | undefined>
  logout: () => Promise<void>
  fetchUser: () => Promise<void>
  setCurrentSiteId: (siteId: SiteId) => Promise<void>
  setCurrentUseCase: (useCase: UseCase) => Promise<void>
  setCurrentUsageType: (usageType: UsageType) => void
}>((set, get) => ({
  user: null,
  async login(email: string, pwd: string, remember: boolean) {
    try {
      const { isSignedIn } = await signIn({
        username: email,
        password: pwd,
      })

      if (!isSignedIn) return

      try {
        const attributes = UserAttributesSchema.parse(await fetchUserAttributes())
        const currentUser = CurrentUserSchema.parse(await getCurrentUser())

        if (remember) {
          await rememberDevice()
        } else {
          await forgetDevice()
        }

        const useCases = JSON.parse(attributes['custom:useCases']) as Array<UseCase>

        const userState = await getUserState(useCases)

        const user = {
          ...attributes,
          ...currentUser,
          role: attributes['custom:role'],
          hasPermission: (permission: Permission) => userPermission(attributes['custom:role'] as Role, permission),
          useCases,
          currentUseCase: userState.currentUseCase,
          currentSiteId: userState.currentSiteId,
          currentUsageType: userState.currentUsageType,
        }

        set({ user })

        return user
      } catch {
        // sign out the user if we cannot fetch the user attributes to prevent
        // the "user already signed in" error from cognito
        await signOut()
      }
    } catch (error) {
      console.error('cannot login', error)

      throw error
    }
  },
  async logout() {
    try {
      const { user } = get()

      if (!user) return

      await signOut()

      set({ user: null })
    } catch (error) {
      console.error('cannot logout', error)
    }
  },
  async fetchUser() {
    // includes user attributes and custom
    const attributes = UserAttributesSchema.parse(await fetchUserAttributes())
    // includes userId
    const currentUser = CurrentUserSchema.parse(await getCurrentUser())
    const useCases = JSON.parse(attributes['custom:useCases']) as Array<UseCase>

    const userState = await getUserState(useCases)

    set({
      user: {
        ...attributes,
        ...currentUser,
        role: attributes['custom:role'],
        hasPermission: (permission: Permission) => userPermission(attributes['custom:role'] as Role, permission),
        useCases: JSON.parse(attributes['custom:useCases']) as Array<UseCase>,
        currentUseCase: userState.currentUseCase,
        currentSiteId: userState.currentSiteId,
        currentUsageType: userState.currentUsageType,
      },
    })
  },
  async setCurrentSiteId(siteId) {
    try {
      const { user } = get()

      if (!user) {
        throw new Error('No user')
      }

      if (user.currentSiteId === siteId) return

      set((state) => ({
        user: {
          ...state.user!,
          currentSiteId: siteId,
        },
      }))

      const payload: PutUserStatePayload = {
        currentSiteId: siteId,
        currentUseCase: user.currentUseCase,
      }

      await apiFetch.put('user/state', { json: payload })
    } catch (error) {
      console.error(error)
    }
  },
  async setCurrentUseCase(useCase: UseCase) {
    const { user } = get()

    if (!user) {
      throw new Error('No user')
    }

    if (!user.currentSiteId) {
      throw new Error('No user site id')
    }

    if (user.currentUseCase === useCase) return

    set((state) => ({
      user: {
        ...state.user!,
        currentUseCase: useCase,
      },
    }))

    const payload: PutUserStatePayload = {
      currentSiteId: user.currentSiteId,
      currentUseCase: useCase,
    }

    await apiFetch.put('user/state', { json: payload })
  },
  setCurrentUsageType(usageType: UsageType) {
    const { user } = get()

    if (!user) {
      throw new Error('No user')
    }

    set((state) => ({
      user: {
        ...state.user!,
        currentUsageType: usageType,
      },
    }))
  },
}))
