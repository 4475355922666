import { UseCase } from '@core/referential/use-case.enum'
import { z } from 'zod'

export const GetUserSchemaResponse = z.array(
  z.object({
    id: z.string().uuid(),
    firstname: z.string(),
    lastname: z.string(),
    phone: z.string().optional().nullable(),
    mail: z.string().email(),
    role: z.string(),
    useCases: z.array(z.nativeEnum(UseCase)),
  }),
)

export type GetUserResponse = z.infer<typeof GetUserSchemaResponse>
